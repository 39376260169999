import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import '@ungap/custom-elements'
// import "tailwindcss/tailwind.css"
// import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import VueI18n from "vue-i18n";

import Maska from 'maska'
Vue.use(Maska)

Vue.use(VueI18n);
Vue.use(VueSweetalert2);
Vue.use(VueTelInput);
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "ru",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
  messages: loadLocaleMessages()
});


// Vue.component('calendar', Calendar)
Vue.use(VueAxios, axios)
// Vue.axios.defaults.baseURL = "https://rentprog.net"
// Vue.axios.defaults.baseURL = "http://localhost:3000"
Vue.axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://rentprog.net"
Vue.use(VueReCaptcha, {
  siteKey: '6Lc8F3YbAAAAAHTqXNnmCYokUy1_hCiZsiOf6zv8',
  loaderOptions: {
    autoHideBadge: true
  }
})
Vue.component('date-picker', DatePicker)
Vue.component('loading', Loading)
Vue.use(vueCustomElement)
Vue.customElement(
  'rentprog-widget',
  App,
  {
    beforeCreateVueInstance(RootComponentDefinition) {
      RootComponentDefinition.i18n = i18n
      return RootComponentDefinition
    }
  }
)

// Директива для ввода телефона v-phone
Vue.directive('phone', {
  bind(el) {
    function replaceNumberForInput(value) {
      let val = ''
      const x = value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/)

      if (!x[2] && x[1] !== '') {
        val = x[1] === '8' ? x[1] : '8' + x[1]
      } else {
        val = !x[3] ? x[1] + x[2] : x[1] + '(' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '')
      }

      return val
    }

    function replaceNumberForPaste(value) {
      const r = value.replace(/\D/g, '')
      let val = r
      // if (val.charAt(0) === '7') {
      //   val = '8' + val.slice(1)
      // }
      return replaceNumberForInput(val)
    }

    el.oninput = function (e) {
      if (!e.isTrusted) {
        return
      }
      this.value = replaceNumberForInput(this.value)
    }

    el.onpaste = function () {
      setTimeout(() => {
        const pasteVal = el.value
        this.value = replaceNumberForPaste(pasteVal)
      })
    }

  }
})
